define('analytics/components/account-update-form', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		account: _ember['default'].inject.service(),
		actions: {
			save: function save() {
				this.debug("save");
				//			let {email, company} = this.getProperties('email', 'company');
				//			this.get('account').save(email, company);
				this.attrs.save(this.get('changeset'));
			}
		}
	});
});