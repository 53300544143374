define('analytics/router', ['exports', 'ember', 'analytics/config/environment'], function (exports, _ember, _analyticsConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _analyticsConfigEnvironment['default'].locationType,
    rootURL: _analyticsConfigEnvironment['default'].rootURL
  });

  Router.map(function () {
    this.route('about');
    this.route('purpose');
    this.route('/');
    this.route('accounts', function () {
      this.route('account', { path: ':account_id' }, function () {
        this.route('show');
        this.route('edit');
      });
    });
    this.route('account', { path: '/account/:account_id' });
    this.route('account-edit', { path: '/account/:account_id/edit' });
    this.route('authenticated');
    this.route('login');
    this.route('register');
    this.route('terms');
    this.route('privacy');
    this.route('projects');
    this.route('project', { path: '/project/:project_id' }, function () {
      this.route('dashboard', function () {
        this.route('show');
        this.route('edit');
      });
      this.route('explore');
      this.route('settings', function () {
        this.route('detail');
        this.route('api_keys');
        this.route('collection');
        this.route('schema');
        this.route('last_events');
      });
      this.route('queries');
    });
    this.route('project-new');
    this.route('project-setup');
  });

  exports['default'] = Router;
});