define('analytics/components/project/dashboard-edit-box', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		timeframes: ['minutes', 'hours', 'days', 'weeks', 'months', 'years'],
		column: _ember['default'].computed('row.columns', function () {
			var i = this.get('index');
			var row = this.get('row');
			if (row && row.columns[i]) {
				var v = row.columns[i];

				// fill in defaults if not already present
				if (typeof v.isCompare === "undefined") {
					v.isCompare = false;
				}
				if (typeof v.compareTimeValue === "undefined") {
					v.compareTimeValue = 1;
				}
				if (typeof v.comparePeriod === "undefined") {
					v.comparePeriod = 'months';
				}

				return v;
			}
			return null;
		}),
		actions: {
			pickComparePeriod: function pickComparePeriod(value) {
				this.set('column.comparePeriod', value);
			},
			pick: function pick(v) {
				var i = this.get('index');
				var row = this.get('row');
				var column = row.columns[i];
				column.queryName = v;
				row.columns.replace(i, column);
				//			row.columns[i].queryName = v;
			}
		}
	});
});