define('analytics/controllers/account/edit', ['exports', 'ember', 'ember-validations'], function (exports, _ember, _emberValidations) {
	exports['default'] = _ember['default'].Controller.extend(_emberValidations['default'], {
		account: _ember['default'].inject.service(),
		session: _ember['default'].inject.service(),
		showErrors: false,
		validations: {
			email: {
				presence: true,
				length: { minimum: 6 }
			},
			company: {
				presence: true,
				length: { minimum: 2 }
			}
		},
		actions: {
			save: function save(changeset) {
				var _this = this;

				this.debug('save');

				var currentAccount = this.get('session.currentAccount');
				var id = changeset.get('id');
				this.debug(id);

				if (id === currentAccount._id) {
					// user is updating their own account
					this.debug("User is updating their own account");
					changeset.save().then(function () {
						_this.get('flashMessages').success("Account updated");
					})['catch'](function (reason) {
						_this.get('flashMessages').danger(reason);
					});
				} else {
					this.debug("User is attempting to update another account");
					var account = this.get('account');
					account.isAllowed('accounts', 'update', currentAccount._id).then(function () {
						changeset.save().then(function () {
							_this.get('flashMessages').success("Account updated");
						})['catch'](function (reason) {
							_this.get('flashMessages').danger(reason);
						});
					})['catch'](function () {
						_this.get('flashMessages').danger("You cannot update someone elses account!");
					});
				}

				// if (this.get('isValid')) {
				// 	let {email, company} = this.getProperties('email', 'company');
				// 	this.debug('email ' + email);

				// 	// this.get('session').login(email, password).then(()=>{
				// 	// 	this.get('flashMessages').success("Signed in successfully");
				// 	// 	this.transitionToPreviousRoute();
				// 	// }).catch((reason)=>{
				// 	// 	this.set("showErrors", true);
				// 	// 	this.get('flashMessages').danger(reason);
				// 	// });
				// } else {
				// 		this.set("showErrors", true);
				// }
			},
			changePassword: function changePassword(oldPassword, newPassword, confirmPassword) {
				var _this2 = this;

				this.debug(oldPassword + ' ' + newPassword + ' ' + confirmPassword);

				this.get('account').changePassword(oldPassword, newPassword, confirmPassword).then(function () {
					_this2.debug("success");
				})['catch'](function () {
					_this2.debug("failed");
				});
			}
		}
	});
});