define('analytics/controllers/project/queries', ['exports', 'ember', 'analytics/config/environment'], function (exports, _ember, _analyticsConfigEnvironment) {
	exports['default'] = _ember['default'].Controller.extend({
		project: _ember['default'].inject.service(),
		queryFinished: false,
		queryRunning: false,
		queryType: false,
		queryJSON: false,
		currentIndex: -1,
		actions: {
			deleteQuery: function deleteQuery() {
				var _this = this;

				if (confirm("Delete this query?")) {
					var project = this.get('project');
					var model = this.get('model');
					var savedQuery = model.get('queries')[parseInt(this.get('currentIndex'))];
					_ember['default'].$.ajax({
						method: "DELETE",
						headers: project.headerForAccess(model),
						contentType: 'application/json',
						url: project.queryEndpoint(model),
						processData: false,
						data: JSON.stringify({ query: savedQuery })
					}).then(function (r) {
						_this.get('flashMessages').success('Query deleted.');
						_this.get('model').reload();
					})['catch'](function (err) {
						_this.get('flashMessages').danger('Error deleting query.');
					});
				}
			},
			runQuery: function runQuery(idx) {
				var _this2 = this;

				this.set('currentIndex', idx);
				var project = this.get('project');
				var model = this.get('model');
				var readKey = model.get('readKey');
				var savedQuery = model.get('queries')[parseInt(idx)];
				console.log(savedQuery);

				this.set('queryRunning', true);

				console.log('----SAVED QUERY----');
				console.log(savedQuery);

				this.set('resultIsCount', false);

				_ember['default'].$.ajax({
					method: "POST",
					headers: project.headerForRead(model),
					contentType: 'application/json',
					url: project.queryEndpoint(model),
					processData: false,
					data: JSON.stringify({ query: savedQuery.query })
				}).then(function (r) {

					_this2.set('jsonPretty', JSON.stringify(r, null, 2));
					_this2.set('resultIsRunning', false);

					console.log('============RESULTS=========');
					console.log(r);
					_this2.set('queryJSON', r);

					if (!r || r.length === 0 || Object.keys(r).length === 0) {
						_this2.get('flashMessages').warning('No results for this criteria.');
						_this2.set('queryType', false);
						return;
					}

					_this2.set('queryRunning', false);
					_this2.set('displayType', savedQuery.displayType);
					_this2.set('queryFinished', true);
				})['catch'](function (err) {
					alert(err);
				});
			}
		}
	});
});