define('analytics/controllers/register', ['exports', 'ember', 'ember-validations'], function (exports, _ember, _emberValidations) {
	exports['default'] = _ember['default'].Controller.extend(_emberValidations['default'], {
		showErrors: false,
		session: _ember['default'].inject.service(),
		account: _ember['default'].inject.service(),
		validations: {
			email: {
				presence: true,
				length: { minimum: 6 }
			},
			password: {
				presence: true,
				length: { minimum: 6 }
			},
			company: {
				presence: true
			}
		},
		actions: {

			register: function register() {
				var _this = this;

				var _getProperties = this.getProperties('email', 'company', 'password');

				var email = _getProperties.email;
				var company = _getProperties.company;
				var password = _getProperties.password;

				this.debug('register');
				this.validate().then(function () {
					_this.debug("Validation ok");
					return _this.get("account").register(email, company, password);
				}).then(function () {
					_this.get('flashMessages').success("You have signed up successfully.");
					_this.transitionTo('index');
				})['catch'](function (reason) {
					_this.set('showErrors', true);
					if (typeof reason === 'string') {
						_this.get('flashMessages').danger(reason, { sticky: true });
					}
				});
			}

		} // actions

	});
});