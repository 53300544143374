define('analytics/components/project-detail-form', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		store: _ember['default'].inject.service(),
		save: function save(changeset) {
			changeset.save();
		},
		deleteProject: function deleteProject(changeset) {
			var _this = this;

			var collections = changeset.get('collections');
			var msg = 'Delete this project?\n\n' + collections.length + ' event collections contain event data!\n\n';

			var list = collections.map(function (c) {
				return c.name;
			});
			msg += 'Including: ' + list.join(', ');

			if (confirm(msg)) {
				this.get('store').findRecord('project', changeset.get('id'), { reload: true }).then(function (rec) {
					return rec.destroyRecord();
				}).then(function () {
					_this.get('flashMessages').warning('Project deleted');
					_this.sendAction('transitionToAccount');
				})['catch'](function (err) {

					_this.get('flashMessages').danger("Error deleting project: " + err);
				});
			}
		}
	});
});