define('analytics/components/explore/query-builder-timeframe-offset', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		timeframes: ['minutes', 'hours', 'days', 'weeks', 'months', 'years'],
		selectedTimeframe: '',
		timeValue: 1,
		didInsertElement: function didInsertElement() {
			console.log('didInsertElement');
			var v = this.get('value');
			var vals = v.split('_');
			this.set('selectedTimeframe', vals[1]);
			this.set('timeValue', vals[0]);
			console.log(vals);
		},
		valueSetter: (function () {
			this.set('value', this.get('timeValue') + '_' + this.get('selectedTimeframe'));
		}).observes('timeValue'),
		actions: {
			enable: function enable() {
				this.sendAction('enable');
			},
			disable: function disable() {
				this.sendAction('disable');
			},
			pickTimeframe: function pickTimeframe(v) {
				this.set('selectedTimeframe', v);
				this.set('value', this.get('timeValue') + '_' + this.get('selectedTimeframe'));
			},
			updateTimeValue: function updateTimeValue() {
				this.get('onUpdateTimeframe')(this.get('timeValue') + '_' + this.get('selectedTimeframe'));
			}

		}
	});
});