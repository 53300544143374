define('analytics/models/project', ['exports', 'ember-data'], function (exports, _emberData) {
	exports['default'] = _emberData['default'].Model.extend({
		accountId: _emberData['default'].attr('string'),
		name: _emberData['default'].attr('string'),
		createdAt: _emberData['default'].attr('date'),
		accessKey: _emberData['default'].attr('string'),
		readKey: _emberData['default'].attr('string'),
		writeKey: _emberData['default'].attr('string'),
		collections: _emberData['default'].attr(),
		account: _emberData['default'].belongsTo('account'),
		queries: _emberData['default'].attr(),
		dashboard: _emberData['default'].attr(),
		collectionCount: Ember.computed(function () {
			if (this.get('collections')) {
				return this.get('collections').length;
			} else {
				return 0;
			}
		}),
		noData: Ember.computed(function () {
			return this.get('collectionCount') === 0;
		}),
		collectionNames: Ember.computed(function () {
			console.log('collectionNamess');
			var names = [];
			this.get('collections').forEach(function (collection, idx) {
				console.log(collection);
				names.push(collection.name);
			});
			return names;
		})
	});
});