define('analytics/components/explore/query-result-pie-chart', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		project: _ember['default'].inject.service(),
		init: function init() {
			this._super.apply(this, arguments);
			//		console.log("INIT");
			var uu = this.get('project').generateShortUUID();
			this.set('chartId', 'chart-' + uu);
		},
		didRender: function didRender() {
			//		console.log('didRender');
			this._super.apply(this, arguments);

			//		console.log('queryJSON');
			var queryJSON = this.get('queryJSON');
			//		console.log(queryJSON);

			var project = this.get('project');

			var maxDetail = 24;

			var labels = [];
			var dataArray = [];
			var c = 0;
			var cc = 0;
			var finalLabel = undefined,
			    finalData = 0;

			queryJSON.forEach(function (rec) {
				c++;
				if (c < maxDetail) {
					labels.push(rec._id);
					dataArray.push(rec.count);
				} else {
					cc++;
					finalData += rec.count;
				}
			});
			labels.push(cc + ' others');
			dataArray.push(finalData);

			// colors
			var backgroundColors = [];
			var hoverColors = [];
			var borderWidths = [];
			var borderColors = [];
			for (var i = 0; i < dataArray.length; i++) {
				if (i < project.colors.length) {
					var clr = project.hexToRGB(project.colors[i]);
					backgroundColors.push('rgba(' + clr.r + ',' + clr.g + ',' + clr.b + ',0.8)');
					hoverColors.push('rgba(' + clr.r + ',' + clr.g + ',' + clr.b + ',1.0)');
					borderWidths.push(1);
					borderColors.push('rgba(255,255,255,0.5)');
				}
			}

			var pieData = {
				labels: labels,
				datasets: [{
					data: dataArray,
					label: 'Data',
					backgroundColor: backgroundColors,
					hoverColors: hoverColors,
					borderWidth: borderWidths,
					borderColor: borderColors
				}]
			};
			//		console.log(`pieData ${maxDetail}`);

			//		console.log('DATA - line chart');
			//		console.log(pieData);

			var ctx = document.getElementById(this.get('chartId'));

			var chartType = "pie";
			if (this.get('donut')) {
				chartType = 'doughnut';
			}
			if (this.get('polar')) {
				chartType = 'polarArea';
			}

			var options = {
				legend: {
					position: 'right',
					labels: {
						boxWidth: 11,
						fontSize: 10
					}
				},
				tooltips: {
					callbacks: {
						label: function label(tooltipItem, data) {
							var allData = data.datasets[tooltipItem.datasetIndex].data;
							var tooltipLabel = data.labels[tooltipItem.index];
							var tooltipData = allData[tooltipItem.index];

							//          	console.log('CHARTCHARTCHART');
							//          	console.log(allData);

							var t = allData.reduce(function (a, b) {
								return a + b;
							}, 0);
							//          	console.log(t);

							var tooltipPercentage = Math.round(tooltipData / t * 100);
							return tooltipLabel + ': ' + tooltipData + ' (' + tooltipPercentage + '%)';
						}
					}
				}
			};

			var chartParams = {
				type: chartType,
				data: pieData,
				options: options
			};

			//		console.log('DATA - chart params');
			//		console.log(chartParams);

			var chart = new Chart(ctx, chartParams);
		}

	});
});