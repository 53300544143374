define('analytics/services/account', ['exports', 'ember', 'analytics/config/environment'], function (exports, _ember, _analyticsConfigEnvironment) {
	exports['default'] = _ember['default'].Service.extend({
		session: _ember['default'].inject.service(),

		isAllowed: function isAllowed(resource, permissions, _account) {
			var _this = this;

			return new _ember['default'].RSVP.Promise(function (resolve, reject) {

				//			this.debug(`isAllowed: ${resource} ${permissions}`);

				// if _account query permission for that account, otherwise use the current account
				//			this.debug(`session: ${JSON.stringify(session)}`);

				var account = undefined;
				if (_account) {
					account = _account;
				} else {
					//				account = this.get('session').get('currentAccount')._id;
					var ca = _this.get('session.currentAccount');
					if (ca) {
						account = ca._id;
					} else {}
				}

				// can't find account?  reject
				if (!account) {
					reject("Can't access accounts that way");
				}

				var url = _analyticsConfigEnvironment['default'].APP.API_ENDPOINT + '/accounts/is_allowed?a=' + account + '&r=' + resource + '&p=' + permissions;

				_ember['default'].$.ajax({
					method: 'GET',
					url: url,
					headers: {
						'Infinite-Auth-Token': _this.get('session.authToken')
					}
				}).then(function () {
					resolve();
				})['catch'](function () {
					reject('Access denied');
				});
			});
		},

		changePassword: function changePassword(uuid, oldPassword, newPassword, confirmPassword) {
			this.debug('changePassword');
			return new _ember['default'].RSVP.Promise(function (resolve, reject) {

				_ember['default'].$.ajax({
					method: 'POST',
					url: _analyticsConfigEnvironment['default'].APP.API_ENDPOINT + '/accounts/change_password',
					data: {
						id: uuid,
						old_password: oldPassword,
						new_password: newPassword
					}
				}).then(function (data) {

					resolve();
				}, function () {
					reject();
				});

				resolve();
			});
		},
		save: function save(email, company) {
			this.debug('save');
			this.debug(email);
			this.debug(company);
		},

		getAccounts: function getAccounts() {
			var _this2 = this;

			return new _ember['default'].RSVP.Promise(function (resolve, reject) {
				var accts = [{ id: 1, email: 'sean@wenzel.net' }, { id: 2, email: 'zhangbo@wenzel.net' }];
				_this2.debug("resolve with: " + accts);
				resolve(accts);
				if (false) {
					reject();
				}
			});
		},

		find: function find(token) {
			var _this3 = this;

			this.debug('find: ' + token);
			return new _ember['default'].RSVP.Promise(function (resolve, reject) {
				var ts = +new Date();
				_ember['default'].$.ajax({
					method: "GET",
					url: _analyticsConfigEnvironment['default'].APP.API_ENDPOINT + '/accounts/by_token/' + token + '?ts=' + ts
				}).then(function (data) {
					// store account info in the session
					_this3.get('session').set('currentAccount', data.account);
					resolve();
				}, function () {
					_this3.debug('reject');
					_this3.get('session').unset('currentAccount');
					reject("Auth expired");
				});
			});
		},
		register: function register(email, company, password) {
			this.debug('Register service: ' + email + ' ' + company + ' ' + password);
			return new _ember['default'].RSVP.Promise(function (resolve, reject) {

				_ember['default'].$.ajax({
					method: "POST",
					url: _analyticsConfigEnvironment['default'].APP.API_ENDPOINT + '/accounts/register',
					data: {
						email: email,
						company: company,
						password: password
					}
				}).then(function (data) {
					console.log('OK: ' + data);
					resolve();
				})['catch'](function (response) {
					console.log(response);
					var err = _ember['default'].get(response, 'responseJSON.error');
					console.log(err);
					reject('Server error: ' + _ember['default'].get(response, 'responseJSON.error'));
				});
			});
		}
	});
});