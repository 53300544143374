define('analytics/routes/project/settings/last-events', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    project: _ember['default'].inject.service(),
    actions: {
      didTransition: function didTransition() {
        this.get('project').setActiveSettingsTab('last_events');
        return false; // Bubble the didTransition event
      }
    }
  });
});