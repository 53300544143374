define('analytics/components/explore/query-builder-group-by-picker', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		actions: {
			enable: function enable() {
				this.sendAction('enable');
			},
			disable: function disable() {
				this.sendAction('disable');
			},
			pick: function pick(v) {
				this.sendAction('action', v);
			}
		}
	});
});