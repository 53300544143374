define('analytics/services/session', ['exports', 'ember', 'ember-debug-logger', 'analytics/config/environment'], function (exports, _ember, _emberDebugLogger, _analyticsConfigEnvironment) {

	var TOKEN_KEY = 'token';

	exports['default'] = _ember['default'].Service.extend({
		debug: (0, _emberDebugLogger['default'])(),
		currentAccount: null,
		account: _ember['default'].inject.service(),
		showAccountsLink: false,
		updatePermissions: function updatePermissions() {
			var _this = this;

			//		this.debug('updatePermissions');
			this.get('account').isAllowed('accounts', 'list', this.get('currentAccount')._id).then(function () {
				//			this.debug('updatePermissions - ok');
				_this.set('showAccountsLink', true);
			})['catch'](function () {
				//			this.debug('updatePermissions - sorry');
				_this.set('showAccountsLink', false);
			});
		},
		login: function login(email, password) {
			var _this2 = this;

			return new _ember['default'].RSVP.Promise(function (resolve, reject) {
				//			this.debug('Email: ' + email + ' ' + 'password: ' + password);

				// authenticate with API
				_ember['default'].$.ajax({
					method: "POST",
					url: _analyticsConfigEnvironment['default'].APP.API_ENDPOINT + '/accounts/authenticate',
					data: {
						email: email,
						password: password
					}
				}).then(function (data) {

					_this2.debug("Return from authenticate");
					console.log(data);

					// save token as cookie
					Cookies.set(TOKEN_KEY, data.token);

					// store account info
					_this2.set('currentAccount', data.account);
					_this2.set('authToken', data.token);
					_this2.updatePermissions();
					resolve();
				}, function () {

					reject("Email and password did not match");
					Cookies.remove(TOKEN_KEY);
				});
			});
		},
		logout: function logout() {
			this.debug("logout()");
			this.set('currentAccount', null);
			this.set('showAccountsLink', false);
			Cookies.remove(TOKEN_KEY);
		},
		// initFromCookieWithPromise(){
		// 	return new Ember.RSVP.Promise((resolve, reject)=>{
		// 		console.log("initFromCookieWithPromise");
		// 		var token = Cookies.get(TOKEN_KEY);
		// 		this.set('authToken', token);
		// 		console.log("token: " + token);
		// 		if (!!token) {
		// 			this.get('account').find(token).then(()=>{
		// 				this.debug('initFromCookieWithPromise - done');
		// 				// nop - account was set by the find operation
		// 				this.updatePermissions();
		// 				resolve();
		// 			}).catch(()=>{
		// 				this.set('currentAccount', null);
		// 				resolve();
		// 			});
		// 		}
		// 	});
		// },
		initializeFromCookie: (function () {
			var _this3 = this;

			return new _ember['default'].RSVP.Promise(function (resolve, reject) {
				//			console.log("initializeFromCookie");
				var token = Cookies.get(TOKEN_KEY);
				_this3.set('authToken', token);
				console.log("token: " + token);
				if (!!token) {
					_this3.get('account').find(token).then(function () {
						//					this.debug('initializeFromCookie - done');
						// nop - account was set by the find operation
						_this3.updatePermissions();
						resolve();
					})['catch'](function () {
						_this3.set('currentAccount', null);
						resolve();
					});
				}
			});
		}).on('init')
	});
});