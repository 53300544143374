define('analytics/routes/project/settings/api-keys', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    project: _ember['default'].inject.service(),
    actions: {
      didTransition: function didTransition() {
        this.get('project').setActiveSettingsTab('api_keys');
        return false; // Bubble the didTransition event
      }
    }
  });
});