define('analytics/mixins/authenticated-route', ['exports', 'ember'], function (exports, _ember) {

	var TOKEN_KEY = 'token';

	exports['default'] = _ember['default'].Mixin.create({
		session: _ember['default'].inject.service(),
		account: _ember['default'].inject.service(),
		beforeModel: function beforeModel(transition) {
			var _this = this;

			// can we auto-login?
			var token = Cookies.get(TOKEN_KEY);
			if (!!token) {
				this.get('account').find(token).then(function () {
					// nop - account was set by the find operation
					_this.updatePermissions();

					if (!_this.get('session.currentAccount')) {
						_this.debug('saving previousTransition');
						var loginController = _this.controllerFor('login');
						loginController.set('previousTransition', transition);
					}
				})['catch'](function () {
					_this.set('currentAccount', null);
				});
			} else {
				if (!this.get('session.currentAccount')) {
					this.debug('saving previousTransition');
					var loginController = this.controllerFor('login');
					loginController.set('previousTransition', transition);
					this.transitionTo('login');
				}
			}
		}
	});
});