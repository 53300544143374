define('analytics/serializers/application', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].JSONAPISerializer.extend({
        //	primaryKey: '_id'

        // only send updates to the backend with attributes that have changed
        serialize: function serialize(snapshot) {
            var _this = this;

            var json = this._super.apply(this, arguments);
            var changedAttributes = snapshot.changedAttributes();
            var attributes = {};

            Object.keys(changedAttributes).forEach(function (key) {
                attributes[_this.keyForAttribute(key)] = changedAttributes[key][1];
            });

            console.log('SERIALIZATION!!!');
            console.log(snapshot);
            console.log(JSON.stringify(snapshot._attributes.dashboard));
            attributes['dashboard'] = snapshot._attributes.dashboard;

            //    console.log(attributes);
            json["data"]["attributes"] = attributes;
            //    console.log(json);

            return json;
        }

    });
});