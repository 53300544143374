define('analytics/services/project', ['exports', 'ember', 'analytics/config/environment'], function (exports, _ember, _analyticsConfigEnvironment) {

	// function componentToHex(c) {
	//     var hex = c.toString(16);
	//     return hex.length == 1 ? "0" + hex : hex;
	// }

	// function rgbToHex(r, g, b) {
	//     return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
	// }

	exports['default'] = _ember['default'].Service.extend({
		tabDashboardActive: false,
		tabExploreActive: false,
		tabQueriesActive: false,
		tabSettingsActive: false,
		tabSettingsDetailActive: false,
		tabSettingsAPIKeysActive: false,
		tabSettingsDataCollectionActive: false,
		tabSettingsSchemaActive: false,
		colors: ['#559ac3', '#556ac3', '#7c55c3', '#c155c3', '#ce5a79', '#dc7360', '#dc9b60', '#dcb460', '#dccc60', '#ced75e', '#8bcb58', '#55c386', '#eda1a4', '#eebda2', '#eecea2', '#eedda2', '#d8e89e', '#9ee098', '#98d8df', '#98b8df', '#9f98df', '#c398df', '#e29ac9'],
		setCurrentFromModel: function setCurrentFromModel(model) {
			this.set('projectID', model.get('id'));
			this.set('writeKey', model.get('writeKey'));
			this.set('accessKey', model.get('accessKey'));
			this.set('readKey', model.get('readKey'));
		},
		hexToRGB: function hexToRGB(hex, a) {
			var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
			return result ? {
				r: parseInt(result[1], 16),
				g: parseInt(result[2], 16),
				b: parseInt(result[3], 16)
			} : null;
		},
		headerForAccess: function headerForAccess(model) {
			var accessKey = model.get('accessKey');
			var headers = {};
			headers[_analyticsConfigEnvironment['default'].APP.AUTH_TOKEN_HEADER_KEY] = accessKey;
			return headers;
		},
		headerForRead: function headerForRead(model) {
			var readKey = model.get('readKey');
			var headers = {};
			headers[_analyticsConfigEnvironment['default'].APP.AUTH_TOKEN_HEADER_KEY] = readKey;
			return headers;
		},
		nFormatter: function nFormatter(num) {
			if (num >= 1000000000) {
				return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
			}
			if (num >= 1000000) {
				return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
			}
			if (num >= 1000) {
				return (num / 1000).toFixed(2).replace(/\.0$/, '') + 'k';
			}
			return num;
		},
		queryEndpoint: function queryEndpoint(model) {
			var projectId = model.get('id');
			return _analyticsConfigEnvironment['default'].APP.API_ENDPOINT + ('/project/' + projectId + '/query');
		},
		buildQuery: function buildQuery(projectId, queryType, collection, timeFrame) {
			var _ref = arguments.length <= 4 || arguments[4] === undefined ? {} : arguments[4];

			var property = _ref.property;
			var interval = _ref.interval;
			var percentile = _ref.percentile;
			var groupBy = _ref.groupBy;
			var filters = _ref.filters;
			var timeFrameOffset = _ref.timeFrameOffset;

			var query = {
				project_id: projectId,
				type: queryType,
				collection: collection,
				timeframe: timeFrame
			};

			if (timeFrameOffset) {
				query['timeframe']['offset'] = timeFrameOffset;
			}

			if (interval) {
				query['interval'] = interval;
			}

			if (property) {
				query['property'] = property;
			}

			if (percentile) {
				query['percentile'] = parseFloat(percentile);
			}

			if (groupBy) {
				query['groupBy'] = groupBy;
			}

			if (filters) {
				query['filters'] = filters;
			}

			return query;
		},
		saveQuery: function saveQuery(model, savedQuery, callback) {

			var projectId = model.get('id');
			var url = _analyticsConfigEnvironment['default'].APP.API_ENDPOINT + ('/project/' + projectId + '/saveQuery');

			var headers = {};
			var accessKey = model.get('accessKey');
			headers[_analyticsConfigEnvironment['default'].APP.AUTH_TOKEN_HEADER_KEY] = accessKey;

			_ember['default'].$.ajax({
				method: "POST",
				headers: headers,
				contentType: 'application/json',
				url: url,
				processData: false,
				data: JSON.stringify(savedQuery)
			}).then(function (r) {
				console.log('success');
				callback();
			})['catch'](function (err) {
				console.log('error');
				console.log(err);
				callback(err);
			});
		},
		findQueryByName: function findQueryByName(model, name) {
			console.log('findQueryByName: ' + name);
			var savedQueries = model.get('queries');
			return savedQueries.find(function (q) {
				return q.name === name;
			});
		},
		setActiveTab: function setActiveTab(tab) {
			this.set('tabSettingsActive', false);
			this.set('tabExploreActive', false);
			this.set('tabDashboardActive', false);
			this.set('tabQueriesActive', false);
			switch (tab) {
				case 'dashboard':
					this.set('tabDashboardActive', true);
					break;
				case 'explore':
					this.set('tabExploreActive', true);
					break;
				case 'settings':
					this.set('tabSettingsActive', true);
					break;
				case 'queries':
					this.set('tabQueriesActive', true);
					break;
			}
		},
		setActiveSettingsTab: function setActiveSettingsTab(tab) {
			this.set('tabSettingsDetailActive', false);
			this.set('tabSettingsAPIKeysActive', false);
			this.set('tabSettingsDataCollectionActive', false);
			this.set('tabSettingsSchemaActive', false);
			this.set('tabSettingsLastEventsActive', false);
			switch (tab) {
				case 'detail':
					this.set('tabSettingsDetailActive', true);
					break;
				case 'api_keys':
					this.set('tabSettingsAPIKeysActive', true);
					break;
				case 'collection':
					this.set('tabSettingsDataCollectionActive', true);
					break;
				case 'schema':
					this.set('tabSettingsSchemaActive', true);
					break;
				case 'last_events':
					this.set('tabSettingsLastEventsActive', true);
					break;
			}
		},
		deleteProject: function deleteProject() {
			confirm("Delete this projects and all of it's data!?");
		},
		analyze: function analyze(model, callback) {
			// setup headers
			var headers = {};
			var accessKey = model.get('accessKey');
			console.log("accessKey: " + accessKey);
			headers[_analyticsConfigEnvironment['default'].APP.AUTH_TOKEN_HEADER_KEY] = accessKey;

			console.log('project.analyze');

			_ember['default'].$.ajax({
				method: "POST",
				headers: headers,
				contentType: 'application/json',
				url: _analyticsConfigEnvironment['default'].APP.API_ENDPOINT + ('/project/' + model.id + '/analyze')
			}).then(function (r) {
				console.log('analyze-finished');
				model.reload();
				callback();
			})['catch'](function (err) {
				console.log(err);
				callback("An error occured during analysis.");
			});
		},
		generateUUID: function generateUUID() {
			return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
				var r = Math.random() * 16 | 0,
				    v = c == 'x' ? r : r & 0x3 | 0x8;
				return v.toString(16);
			});
		},
		generateShortUUID: function generateShortUUID() {
			return Math.random().toString(36).substr(2, 5);
		},
		getSchema: function getSchema(model, collection, callback) {
			// setup headers
			var headers = {};
			var accessKey = model.get('accessKey');
			var projectId = model.get('id');
			console.log("accessKey: " + accessKey);
			headers[_analyticsConfigEnvironment['default'].APP.AUTH_TOKEN_HEADER_KEY] = accessKey;
			_ember['default'].$.ajax({
				method: "GET",
				headers: headers,
				contentType: 'application/json',
				url: _analyticsConfigEnvironment['default'].APP.API_ENDPOINT + ('/project/' + projectId + '/schema/' + collection)
			}).then(function (r) {
				callback(r);
			})['catch'](function (err) {
				console.log(err);
			});
		},
		lastEvents: function lastEvents(model, collection, callback) {
			// setup headers
			var headers = {};
			var accessKey = model.get('accessKey');
			var projectId = model.get('id');
			console.log("accessKey: " + accessKey);
			headers[_analyticsConfigEnvironment['default'].APP.AUTH_TOKEN_HEADER_KEY] = accessKey;
			_ember['default'].$.ajax({
				method: "GET",
				headers: headers,
				contentType: 'application/json',
				url: _analyticsConfigEnvironment['default'].APP.API_ENDPOINT + ('/project/' + projectId + '/lastEvents/' + collection)
			}).then(function (r) {
				callback(r);
			})['catch'](function (err) {
				console.log(err);
			});
		}

	});
});