define('analytics/controllers/project/dashboard/edit', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Controller.extend({
		dashboard: _ember['default'].inject.controller('project.dashboard'),
		actions: {
			finished: function finished() {
				console.log('controller.finished');
				this.get('dashboard').set('isEditing', false);
				this.get('model').save();
				this.transitionToRoute('project.dashboard');
			}
		}
	});
});