define('analytics/components/explore/bubble-graph', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		didRender: function didRender() {
			console.log('didRender');
			this._super.apply(this, arguments);

			var graphData = this.get('data');
			console.log('container: ' + this.get('domContainer'));
			var ctx = document.getElementById("myChart");

			var options = {
				maintainAspectRatio: true
			};

			console.log(this.get('data'));

			var data = {
				datasets: [{
					label: 'First Dataset',
					data: this.get('data'),
					backgroundColor: "#FF6384",
					hoverBackgroundColor: "#FF6384"
				}]
			};

			var bubbleChart = new Chart(ctx, {
				type: 'bubble',
				data: data,
				options: options
			});

			// var myChart = new Chart(ctx, {
			//     type: 'bar',
			//     data: {
			//         labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
			//         datasets: [{
			//             label: '# of Votes',
			//             data: [12, 19, 3, 5, 2, 3],
			//             backgroundColor: [
			//                 'rgba(255, 99, 132, 0.2)',
			//                 'rgba(54, 162, 235, 0.2)',
			//                 'rgba(255, 206, 86, 0.2)',
			//                 'rgba(75, 192, 192, 0.2)',
			//                 'rgba(153, 102, 255, 0.2)',
			//                 'rgba(255, 159, 64, 0.2)'
			//             ],
			//             borderColor: [
			//                 'rgba(255,99,132,1)',
			//                 'rgba(54, 162, 235, 1)',
			//                 'rgba(255, 206, 86, 1)',
			//                 'rgba(75, 192, 192, 1)',
			//                 'rgba(153, 102, 255, 1)',
			//                 'rgba(255, 159, 64, 1)'
			//             ],
			//             borderWidth: 1
			//         }]
			//     },
			//     options: {
			//     	onResize: function(c,sz){
			//     		console.log(sz);
			//     		console.log(c);
			//     	},
			//     	maintainAspectRatio: true,
			//         scales: {
			//             yAxes: [{
			//                 ticks: {
			//                     beginAtZero:true
			//                 }
			//             }]
			//         }
			//     }
			// });
		}
	});
});