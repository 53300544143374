define('analytics/helpers/atan', ['exports', 'ember-math-helpers/helpers/atan'], function (exports, _emberMathHelpersHelpersAtan) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberMathHelpersHelpersAtan['default'];
    }
  });
  Object.defineProperty(exports, 'atan', {
    enumerable: true,
    get: function get() {
      return _emberMathHelpersHelpersAtan.atan;
    }
  });
});