define('analytics/components/project/dashboard-show-box', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		isCompare: false,
		type: null,
		project: _ember['default'].inject.service(),
		loading: true,
		didLaunchLoad: false,
		spinnerMarginTop: _ember['default'].computed(function () {
			var h = parseInt(this.get('row').height) / 2 - 55;
			//		console.log(h);
			return _ember['default'].String.htmlSafe('' + h);
		}),
		init: function init() {
			this._super.apply(this, arguments);
			var uu = this.get('project').generateShortUUID();
			this.set('boxId', 'box-' + uu);
		},
		didRender: function didRender() {
			//		console.log('rendering');
			if (!this.get('didLaunchLoad')) {
				this.set('didLaunchLoad', true);
				this.send('load');
			}
		},
		actions: {
			load: function load() {
				var _this = this;

				console.log('dashboard-show.load');
				var model = this.get('model');
				var row = this.get('row');
				this.set('height', row.height);

				// determine the width of the container
				var domId = this.get('elementId');
				var width = _ember['default'].$('#' + domId).width();
				this.set('width', width);

				var column = row.columns[this.get('index')];
				if (column) {
					this.set('label', column.queryName);
					console.log("Column:");
					console.log(column);
				} else {
					console.log("Missing column: " + this.get('index'));
					this.set('loading', false);
					return;
				}

				var project = this.get('project');
				var savedQuery = project.findQueryByName(model, column.queryName);
				if (savedQuery) {
					this.set('type', savedQuery.displayType);
					if (column.isCompare) {
						console.log('~~~~~~~~~isCompare~~~~~~~');
					}
					console.log(savedQuery);

					_ember['default'].$.ajax({
						method: "POST",
						headers: project.headerForRead(model),
						contentType: 'application/json',
						url: project.queryEndpoint(model),
						processData: false,
						data: JSON.stringify({ query: savedQuery.query })
					}).then(function (r) {

						// if the user has navigated away from the page and the promise returns
						// this avoids throwing an assertion error if the object no longer exists
						if (_this.isDestroyed) {
							return;
						}

						_this.set('jsonPretty', JSON.stringify(r, null, 2));

						console.log('============RESULTS=========');
						console.log(r);
						_this.set('data', r);

						if (!r || r.length === 0 || Object.keys(r).length === 0) {
							_this.get('flashMessages').warning('No results for this criteria.');
							_this.set('type', false);
							return;
						}

						// Compare to previous time period
						if (column.isCompare) {
							console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~');

							_this.set('isCompare', true);

							// create duplicate query injecting the time period offset
							var compareQuery = Object.assign({}, savedQuery);
							compareQuery.query.timeframe.offset = column.compareTimeValue + '_' + column.comparePeriod;
							console.log(compareQuery);

							_ember['default'].$.ajax({
								method: "POST",
								headers: project.headerForRead(model),
								contentType: 'application/json',
								url: project.queryEndpoint(model),
								processData: false,
								data: JSON.stringify(compareQuery)
							}).then(function (r) {

								console.log('============COMPARE RESULTS=========');
								r.timeValue = column.compareTimeValue;
								r.timePeriod = column.comparePeriod;
								console.log(r);

								_this.set('compareData', r);

								_this.set('loading', false);
							})['catch'](function (err) {
								alert(JSON.stringify(err));
							});
						} else {
							_this.set('loading', false);
						}
					})['catch'](function (err) {
						alert(err);
					});
				} else {
					this.set('loading', false);
				}
			}
		}
	});
});