define('analytics/routes/project/settings', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    project: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),
    actions: {
      didTransition: function didTransition() {
        //      console.log('settings didTransition: ' + this.routeName);
        this.get('project').setActiveTab('settings');
        this.get('project').setActiveSettingsTab('');
        return true; // Bubble the didTransition event
      },
      transitionToAccount: function transitionToAccount() {
        this.transitionTo('/account/' + this.get('session.currentAccount._id'));
      }
    }
  });
});