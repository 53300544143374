define('analytics/components/project-sparkline', ['exports', 'ember', 'analytics/config/environment'], function (exports, _ember, _analyticsConfigEnvironment) {

	function nFormatter(num) {
		if (num >= 1000000000) {
			return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
		}
		if (num >= 1000000) {
			return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
		}
		if (num >= 1000) {
			return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'k';
		}
		return num;
	}

	exports['default'] = _ember['default'].Component.extend({
		session: _ember['default'].inject.service(),
		actions: {
			sparkline: function sparkline() {
				var _this = this;

				var collection = this.get('collection');
				//			console.log('name: ' + collection.name + ' collectionName: ' + collection.collection_name);

				// get the data

				// setup headers
				var headers = {};
				var model = this.get('model');
				var readKey = model.get('readKey');
				headers[_analyticsConfigEnvironment['default'].APP.AUTH_TOKEN_HEADER_KEY] = readKey;

				var project_id = model.get('id');

				// make the ajax call
				_ember['default'].$.ajax({
					method: "GET",
					headers: headers,
					url: _analyticsConfigEnvironment['default'].APP.API_ENDPOINT + ('/project/' + project_id + '/sparkline/' + collection.collection_name)
				}).then(function (data) {
					//				console.log('Sparkline results');
					//				console.log(data);

					// set the total count in humanized terms
					var _count = data.reduce(function (a, b) {
						return a + b.count;
					}, 0);
					_this.set('totalCount', nFormatter(_count));

					// convert into graphable data array
					var d = data.map(function (dp) {
						return [new Date(dp['_id']), dp.count];
					});

					// back fill in zeros for the last 30 days
					var d30ago = new Date(new Date().setDate(new Date().getDate() - 30));
					var ts30 = d30ago.valueOf();
					var tsStart = data[0]['_id'].valueOf() - 1000 * 60 * 60 * 4;
					for (var i = tsStart; i > ts30; i -= 1000 * 60 * 60 * 4) {
						//					console.log(new Date(i));
						d.splice(0, 0, [new Date(i), 0]);
					}

					// inject labels
					d.splice(0, 0, ['Date', 'Count']);

					// save as a property
					_this.set('data', d);
					//				console.log(d);

					// draw the chart
					google.charts.load('current', { 'packages': ['corechart'] });
					google.charts.setOnLoadCallback(drawChart);

					function drawChart() {
						var data = google.visualization.arrayToDataTable(d);
						var options = {
							colors: [_analyticsConfigEnvironment['default'].APP.BLUE_PRIMARY],
							interpolateNulls: true,
							vAxis: {
								ticks: [],
								gridlines: {
									color: 'transparent'
								},
								minValue: 0,
								// viewWindow: {
								// 	min: 0
								// },
								baselineColor: 'transparent'
							},
							hAxis: {
								ticks: [],
								gridlines: {
									color: 'transparent'
								}
							},
							animation: {
								duration: 250,
								startup: true
							},
							title: '30 days',
							curveType: 'function',
							legend: {
								position: 'none'
							}
						};
						var chart = new google.visualization.LineChart(document.getElementById(collection.collection_name));
						chart.draw(data, options);
					};
				})['catch'](function (err) {
					//				console.log('Sparkline error');
					//				console.log(err);
				});
			}
		},
		didRender: function didRender() {
			//		console.log('didRender');
		},
		didInsertElement: function didInsertElement() {
			//		console.log('didInsertElement');
			this.triggerAction({
				action: 'sparkline',
				target: this
			});
		}
		// graphData: Ember.computed('project.sparkLineData', function(){
		// 	console.log('computed');

		// 	let d = this.get('project.sparkLineData');
		// 	return d.length;
		// })

	});
});