define('analytics/adapters/application', ['exports', 'ember', 'ember-data', 'analytics/config/environment'], function (exports, _ember, _emberData, _analyticsConfigEnvironment) {

	//export default DS.RESTAdapter.extend({
	exports['default'] = _emberData['default'].RESTAdapter.extend({
		host: _analyticsConfigEnvironment['default'].APP.API_ENDPOINT,
		headers: _ember['default'].computed(function () {
			console.log('Infinite-Auth-Token', Cookies.get('token'));
			return {
				'Infinite-Auth-Token': Cookies.get('token')
			};
		}).volatile()
	});
});