define('analytics/routes/project/dashboard/edit', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Route.extend({
		afterModel: function afterModel(model, transition) {
			// if there's no dashboard for this project, create it now
			var dashboard = model.get('dashboard');
			if (!dashboard) {
				model.set('dashboard', { name: null, rows: [] });
			}
		}

	});
});