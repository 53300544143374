define('analytics/components/explore/query-result-bar-chart', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		project: _ember['default'].inject.service(),
		init: function init() {
			this._super.apply(this, arguments);
			var uu = this.get('project').generateShortUUID();
			this.set('chartId', 'chart-' + uu);
		},
		didRender: function didRender() {
			//		console.log('didRender');
			this._super.apply(this, arguments);

			//		console.log('queryJSON');
			var queryJSON = this.get('queryJSON');
			//		console.log(queryJSON);

			var project = this.get('project');

			var maxDetail = 24;

			var labels = [];
			var dataArray = [];
			var c = 0;
			var cc = 0;
			var finalLabel = undefined,
			    finalData = 0;

			queryJSON.forEach(function (rec) {
				c++;
				if (c < maxDetail) {
					labels.push(rec._id);
					dataArray.push(rec.count);
				} else {
					cc++;
					finalData += rec.count;
				}
			});
			labels.push(cc + ' others');
			dataArray.push(finalData);

			// colors
			var backgroundColors = [];
			var hoverColors = [];
			for (var i = 0; i < dataArray.length; i++) {
				if (i < project.colors.length) {
					var clr = project.hexToRGB(project.colors[i]);
					backgroundColors.push('rgba(' + clr.r + ',' + clr.g + ',' + clr.b + ',0.8)');
					hoverColors.push('rgba(' + clr.r + ',' + clr.g + ',' + clr.b + ',1.0)');
				}
			}

			var barData = {
				labels: labels,
				datasets: [{
					data: dataArray,
					label: 'Data',
					backgroundColor: backgroundColors,
					hoverColors: hoverColors
				}]
			};
			// console.log(`barData ${maxDetail}`);

			// console.log('DATA - line chart');
			// console.log(barData);

			var ctx = document.getElementById(this.get('chartId'));

			var chartType = "horizontalBar";
			if (this.get('columns')) {
				chartType = 'bar';
			}

			var options = {};

			var chartParams = {
				type: chartType,
				data: barData,
				options: options
			};

			// console.log('DATA - chart params');
			// console.log(chartParams);

			var chart = new Chart(ctx, chartParams);
		}

	});
});