define('analytics/controllers/project/settings/schema', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Controller.extend({
		schema: [],
		schemaLoading: false,
		project: _ember['default'].inject.service(),
		actions: {
			analyze: function analyze(model) {
				console.log('analyze');
				this.set('schemaLoading', true);
				var self = this;
				this.get('project').analyze(model, function (err) {
					self.set('schemaLoading', false);
					if (err) {
						alert(err);
					}
				});
			},
			pickCollection: function pickCollection(v) {
				var collections = this.get('model').get('collections');

				console.log(v);
				var collection = collections.find(function (c) {
					return c.name == v;
				});

				var list = collection.fields;
				this.set('schema', list);
			}
		}
	});
});