define('analytics/adapters/project', ['exports', 'ember', 'ember-data', 'analytics/config/environment'], function (exports, _ember, _emberData, _analyticsConfigEnvironment) {

	//export default DS.RESTAdapter.extend({
	exports['default'] = _emberData['default'].RESTAdapter.extend({
		project: _ember['default'].inject.service(),
		session: _ember['default'].inject.service(),
		host: _analyticsConfigEnvironment['default'].APP.API_ENDPOINT,
		headers: _ember['default'].computed(function () {
			var h = {};
			h[_analyticsConfigEnvironment['default'].APP.AUTH_TOKEN_HEADER_KEY] = this.get('project').accessKey;
			h[_analyticsConfigEnvironment['default'].APP.AUTH_SESSION_HEADER_KEY] = Cookies.get('token');
			return h;
		}).volatile()
	});
});