define('analytics/routes/accounts', ['exports', 'ember', 'analytics/mixins/authenticated-route'], function (exports, _ember, _analyticsMixinsAuthenticatedRoute) {
	exports['default'] = _ember['default'].Route.extend(_analyticsMixinsAuthenticatedRoute['default'], {
		account: _ember['default'].inject.service(),
		session: _ember['default'].inject.service(),
		beforeModel: function beforeModel(transition) {
			var _this = this;

			//		this.debug("beforeModel!!!");
			//		this.debug(this.get('session.currentAccount'));

			this.get('account').isAllowed('accounts', 'list').then(function () {
				console.log('allowed');
			})['catch'](function (reason) {
				console.log('rejected');
				_this.get('flashMessages').danger(reason);
				_this.transitionTo('/');
			});
		},
		model: function model() {
			return this.store.findAll('account');
		}
	});
});