define('analytics/controllers/application', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Controller.extend({
		session: _ember['default'].inject.service(),
		actions: {
			logout: function logout() {
				this.get('session').logout();
				this.transitionToRoute('index');
			}
		}
	});
});