define('analytics/controllers/project/explore', ['exports', 'ember', 'analytics/config/environment', 'moment'], function (exports, _ember, _analyticsConfigEnvironment, _moment) {
	exports['default'] = _ember['default'].Controller.extend({
		queryParams: ['queryIndex'],
		queryIndex: null,
		project: _ember['default'].inject.service(),
		saveQueryDisabled: true,
		chartsInitialized: false,
		collection: {},
		collectionError: false,
		count: 0,
		fields: [],
		allFields: [],
		filters: [],
		graphData: [],
		groupByEnabled: false,
		intervalEnabled: false,
		interval: 'days',
		jsonResults: {},
		jsonPretty: "",
		percentile: 95,
		percentileError: false,
		propertyError: false,
		queryTypeError: false,
		queryNeedsProperty: false,
		queryNeedsPercentile: false,
		queryName: '',
		query: '',
		queryEndpoint: _ember['default'].computed(function () {
			var projectId = this.get('model').get('id');
			return _analyticsConfigEnvironment['default'].APP.API_ENDPOINT + ('/project/' + projectId + '/query');
		}),
		queryJSON: _ember['default'].computed('query', function () {
			return JSON.stringify(this.get('query'), null, 2);
		}),
		resultIsCount: false,
		resultIsStart: true,
		resultIsRunning: false,
		resultIsInterval: false,
		resultTypes: ['JSON'],
		selectedCollection: null,
		selectedProperty: null,
		selectedQueryType: null,
		selectedResultType: 'JSON',
		selectedGroupBy: null,
		timeFrame: 'previous_14_days',
		timeFrameOffsetEnabled: false,
		timeFrameOffset: '14_days',
		timeFrameOffsetObserver: (function () {
			this.send('buildQuery');
		}).observes('timeFrameOffset'),
		updateTimeFrame: (function () {
			this.send('buildQuery');
		}).observes('timeFrame'),
		analyzeFields: function analyzeFields() {
			console.log('need to analyze');
			this.set('resultIsRunning', true);
			var self = this;
			this.get('project').analyze(model, function () {
				model.reload();
				var collection = model.get('collections').find(function (item) {
					return item.name === selected;
				});
				self.set('collection', collection);
				self.set('fields', collection.fields);
				self.set('resultIsRunning', false);
			});
		},
		updateQueryName: (function () {
			var qn = this.get('queryName');
			if (qn.length > 0) {
				this.set('saveQueryDisabled', false);
			} else {
				this.set('saveQueryDisabled', true);
			}
		}).observes('queryName'),
		actions: {
			'new': function _new() {
				this.set('queryName', '');
				this.set('filers', []);
				this.set('selectedQueryType', null);
				this.set('selectedCollection', null);
				this.set('intervalEnabled', false);
				this.set('groupByEnabled', false);
				this.set('queryIndex', null);
				this.set('resultIsStart', true);
				this.set('resultIsCount', false);
				this.set('resultIsRunning', false);
			},
			loadSavedQuery: function loadSavedQuery() {
				console.log('Loading saved query');

				var query = this.get('model').get('queries')[this.get('queryIndex')];
				console.log(query);

				this.set('selectedResultType', query.displayType);
				this.set('queryName', query.name);
				this.set('selectedQueryType', query.query.type);
				this.set('selectedCollection', query.query.collection);
				this.send('setupFields');

				// timeframe
				this.set('timeFrame', query.query.timeframe.relative);
				if (query.query.interval) {
					this.set('interval', query.query.interval);
					this.set('intervalEnabled', true);
				} else {
					this.set('intervalEnabled', false);
				}

				// timeframe offset
				if (query.query.timeframe.offset) {
					this.set('timeFrameOffsetEnabled', true);
					this.set('timeFrameOffset', query.query.timeframe.offset);
				} else {
					this.set('timeFrameOffsetEnabled', false);
				}

				// group by
				if (query.query.groupBy) {
					this.set('selectedGroupBy', query.query.groupBy);
					this.set('groupByEnabled', true);
				} else {
					this.set('groupByEnabled', false);
				}

				// filters
				if (query.query.filters) {
					this.set('filters', query.query.filters);
				} else {
					this.set('filters', []);
				}

				// property field
				if (this.get('selectedQueryType') === 'count') {
					this.set('queryNeedsProperty', false);
				} else {
					this.set('queryNeedsProperty', true);
					this.set('selectedProperty', query.query.property);
				}

				this.send('buildQuery');
				this.send('execute');
				console.log(query);
			},
			buildQuery: function buildQuery() {
				console.log('buildQuery');

				var options = {};

				// interval?
				if (this.get('intervalEnabled')) {
					options['interval'] = this.get('interval');
				}

				// needs a property?
				if (this.get('queryNeedsProperty')) {
					options['property'] = this.get('selectedProperty');
				}

				// needs percentile?
				if (this.get('queryNeedsPercentile')) {
					options['percentile'] = this.get('percentile');
				}

				if (this.get('groupByEnabled')) {
					options['groupBy'] = this.get('selectedGroupBy');
				}

				if (this.get('filters').length > 0) {
					options['filters'] = this.get('filters');
				}

				if (this.get('timeFrameOffsetEnabled')) {
					options['timeFrameOffset'] = this.get('timeFrameOffset');
				}

				this.set('query', this.get('project').buildQuery(this.get('id'), this.get('selectedQueryType'), this.get('selectedCollection'), { relative: this.get('timeFrame') }, options));
			},
			resetState: function resetState() {
				this.set('resultIsRunning', false);
				this.set('resultIsCount', false);
				this.set('resultIsStart', true);
				this.set('resultIsInterval', false);
			},
			pickResultType: function pickResultType(value) {
				this.set('selectedResultType', value);
				if (value === 'Line' && this.get('resultIsInterval')) {
					this.send('drawIntervalChart');
				}
			},
			execute: function execute() {
				var _this = this;

				console.log('execute');
				var model = this.get('model');
				var project = this.get('project');
				var qt = this.get('selectedQueryType');
				var col = this.get('selectedCollection');
				var property = this.get('selectedProperty');
				var percentile = this.get('percentile');
				// let groupBy = this.get('selectedGroupBy');
				// let filters = this.get('filters');

				this.send('buildQuery');

				// validate
				this.set('queryTypeError', !qt || qt === 'undefined');
				this.set('collectionError', !col || col === 'undefined');

				// property required?
				if (this.get('queryNeedsProperty')) {
					this.set('propertyError', !property || property === 'undefined' || property.length === 0);
				} else {
					this.set('propertyError', false);
				}

				// percentile required
				if (this.get('queryNeedsPercentile')) {
					this.set('percentileError', !(parseFloat(percentile) > 0 && parseFloat(percentile) < 100));
				} else {
					this.set('percentileError', false);
				}

				// don't continue if there's validation errors
				if (this.get('queryTypeError') || this.get('collectionError') || this.get('propertyError') || this.get('percentileError')) {
					return;
				}

				this.set('resultIsRunning', true);
				this.set('resultIsCount', false);
				this.set('resultIsStart', false);
				this.set('resultIsInterval', false);

				// setup headers
				var headers = {};
				var readKey = model.get('readKey');
				headers[_analyticsConfigEnvironment['default'].APP.AUTH_TOKEN_HEADER_KEY] = readKey;

				console.log('----QUERY----');
				console.log(this.get('query'));

				this.set('resultIsCount', false);

				_ember['default'].$.ajax({
					method: "POST",
					headers: headers,
					contentType: 'application/json',
					url: this.get('queryEndpoint'),
					processData: false,
					data: JSON.stringify({ query: this.get('query') })
				}).then(function (r) {

					_this.set('jsonPretty', JSON.stringify(r, null, 2));
					_this.set('resultIsRunning', false);

					console.log('===RESULTS===');
					console.log(r);

					if (!r || r.length === 0 || Object.keys(r).length === 0) {
						_this.send('resetState');
						_this.get('flashMessages').warning('No results for this criteria.');
						return;
					}

					_this.set('jsonResults', r);

					// Count
					if (r.count != undefined) {
						_this.set('resultIsCount', true);
						_this.set('count', _this.get('project').nFormatter(r.count));
						_this.set('resultTypes', ['Count', 'JSON']);
						_this.set('selectedResultType', 'Count');
					} else {

						//
						// Interval, not grouped
						//
						if (_this.get('intervalEnabled') && !_this.get('groupByEnabled')) {
							console.log("interval - no groupBy");

							// tableData
							var tableData = _this.get('jsonResults').map(function (item) {
								return [new Date(item.timeframe.start), item.count];
							});
							console.log('===tableData===');
							_this.set('tableData', tableData);
							console.log(tableData);
							_this.set('tableHeader', ['Date', 'Count']);

							_this.set('resultIsInterval', true);
							_this.set('resultTypes', ['Area', 'JSON', 'Line', 'Table']);
							//						this.set('selectedResultType', 'Area');
						}

						//
						// group by - no interval
						//

						if (_this.get('groupByEnabled') && !_this.get('intervalEnabled')) {

							console.log('GROUPBY no interval');

							// table
							var header = [_this.get('selectedGroupBy'), 'Count'];
							var tableData = _this.get('jsonResults').map(function (item) {
								return [item._id, item.count];
							});
							_this.set('tableData', tableData);
							_this.set('tableHeader', header);

							_this.set('resultTypes', ['Bar', 'Column', 'Donut', 'JSON', 'Pie', 'Table', 'Polar']);
							//						this.set('selectedResultType', 'Bar');
							if (!_this.get('selectedResultType')) {
								_this.set('selectedResultType', 'Bar');
							}
							if (_this.get('selectedResultType') === 'JSON') {
								_this.set('selectedResultType', 'Bar');
							}
						}

						//
						// interval and group by

						if (_this.get('groupByEnabled') && _this.get('intervalEnabled')) {
							(function () {

								var header = r.map(function (rec) {
									return rec._id;
								}).sort();
								header.splice(0, 0, 'Timestamp');

								// yikes - now we need to assemble into a table

								// walk through all of the data and extract a list of timestamps
								var timestamps = new Set();
								var c = 0;
								r.forEach(function (rec) {
									rec.tc.forEach(function (tc) {
										c++;
										timestamps.add(tc.ts);
									});
								});
								console.log('Processed: ' + c + ' Uniques: ' + timestamps.size);

								var headWarning = false;
								if (c > 10000) {
									var formattedCount = _this.get('project').nFormatter(c);
									var _msg = 'The criteria of this query results in ' + formattedCount + ' cells!  If you continue your web browser may not be able to handle such a complex set of data.  Click cancel to disable visualizations that may overload your web browser, and adjust the criteria and select a narrower timeframe or interval.  Click OK to continue, you have been warned! :)';
									if (!confirm(_msg)) {
										headWarning = true;
									}
								}

								// table view

								var tsArray = Array.from(timestamps).sort();

								// create the empty results array
								var table = [];
								tsArray.forEach(function (e, idx) {
									var row = [new Date(e)];
									header.forEach(function (f, idx) {
										row.push(0);
									});
									table.push(row);
								});

								var bubbleData = [];

								r.forEach(function (rec) {
									rec.tc.forEach(function (tc) {

										// table
										var row = tsArray.indexOf(tc.ts);
										var col = header.indexOf(rec._id);
										table[row][col] = tc.c;
										//								console.log(`${row}x${col}: ${tc.c}`);

										// bubble
										bubbleData.push({ x: col, y: row, r: tc.c });
									});
								});

								// table data
								var tableData = table.slice(1);
								//						console.log('====tableData====');
								//						console.log(tableData);
								_this.set('tableData', tableData);

								//
								// end line chart data
								//

								_this.set('graphData', table);
								_this.set('tableHeader', header);
								_this.set('bubbleData', bubbleData);

								if (headWarning) {
									_this.set('resultTypes', ['JSON']);
									_this.set('selectedResultType', 'JSON');
								} else {
									_this.set('resultTypes', ['Area', 'Bar', 'Column', 'JSON', 'Line', 'Table', 'Bubble']);
									//							this.set('selectedResultType', 'Area');
								}

								//console.log(timestamps);
							})();
						}

						console.log('rows result returned');
						//					console.log(r);
					}
				})['catch'](function (err) {
					_this.set('result');
					console.log('ERRRRRROR');

					_this.set('resultIsRunning', false);
					_this.set('resultIsStart', true);
					var flash = _this.get('flashMessages');

					if (err['responseText']) {

						var json = JSON.parse(err.responseText);

						if (json['errors']) {
							var errors = json.errors;
							console.log(errors);
							errors.forEach(function (error) {
								var msg = error.status + ' ' + error.title + ' ' + error.detail + '}';
								flash.danger(msg);
							});
						} else {
							var j = JSON.parse(err['responseText']);
							if (j['numDataPoints']) {
								var numDataPoints = j['numDataPoints'];
								var formattedNum = _this.get('project').nFormatter(numDataPoints);
								var _msg2 = 'The timeframe and interval selected would result in ' + formattedNum + ' data points; much too granular to convey meaningful results.  Adjust the timeframe and/or interval accordingly.';
								_this.get('flashMessages').danger(_msg2, { timeout: 6000 });
								console.log('resultIsCount');
								console.log(_this.get('resultIsCount'));
							}
						}
					} else {
						_this.get('flashMessages').danger(JSON.stringify(err));
					}

					console.log(err);
				});

				var msg = this.get('selectedQueryType') + ' ' + this.get('selectedCollection') + ' ' + this.get('timeFrame');
				//			alert(msg);
			},
			pickQueryType: function pickQueryType(value) {
				console.log('pickQueryType');
				this.set('selectedQueryType', value);

				// show property select?
				this.set('queryNeedsProperty', value !== 'count');

				// show percentile select?
				this.set('queryNeedsPercentile', value === 'percentile');

				this.send('setupFields');

				this.send('buildQuery');
			},
			analyzeFields: function analyzeFields() {
				console.log('Analyzing properties');
				this.set('resultIsRunning', true);

				var model = this.get('model');
				var self = this;

				this.get('project').analyze(model, function () {
					model.reload();
					self.send('setupFields');
					self.set('resultIsRunning', false);
				});
			},
			setupFields: function setupFields() {
				// set the fields
				var model = this.get('model');

				var selected = this.get('selectedCollection');
				var collection = model.get('collections').find(function (item) {
					return item.name === selected;
				});

				console.log('collection section');

				if (collection) {
					this.set('collection', collection);
					if (collection.fields && collection.fields.length > 0) {

						this.set('allFields', collection.fields.map(function (f) {
							return f.name;
						}));

						// filter fields depending on type of analysis
						switch (this.get('selectedQueryType')) {
							case 'count':
								this.set('fields', collection.fields);
								break;
							case 'count_unique':
								this.set('fields', collection.fields);
								break;
							case 'select_unique':
								this.set('fields', collection.fields);
								break;
							default:
								var _fields = collection.fields.filter(function (f) {
									return f.type == 'number';
								});
								this.set('fields', _fields);
								break;
						}
					} else {
						this.send('analyzeFields');
					}
				} else {
					console.log("No collection was selected");
				}
			},
			pickCollection: function pickCollection(value) {
				console.log('pickCollection');
				this.set('selectedCollection', value);
				this.set('collectionError', false);
				this.send('setupFields');
				this.send('buildQuery');
			},
			updateTimeframe: function updateTimeframe(v) {
				alert(v);
				//			this.set('timeFrame',v);
			},
			pickInterval: function pickInterval(v) {
				this.set('interval', v);
				this.send('buildQuery');
			},
			enableInterval: function enableInterval() {
				this.set('intervalEnabled', true);
				this.send('buildQuery');
			},
			disableInterval: function disableInterval() {
				this.set('intervalEnabled', false);
				this.send('buildQuery');
			},
			enableTimeFrameOffset: function enableTimeFrameOffset() {
				this.set('timeFrameOffsetEnabled', true);
				this.send('buildQuery');
			},
			disableTimeFrameOffset: function disableTimeFrameOffset() {
				this.set('timeFrameOffsetEnabled', false);
				this.send('buildQuery');
			},
			pickProperty: function pickProperty(v) {
				this.set('selectedProperty', v);
				this.send('buildQuery');
				this.set('propertyError', false);
			},
			enableGroupBy: function enableGroupBy() {
				if (this.get('selectedCollection')) {
					this.set('groupByEnabled', true);
					this.send('buildQuery');
				} else {
					this.set('collectionError', true);
				}
			},
			disableGroupBy: function disableGroupBy() {
				this.set('groupByEnabled', false);
				this.send('buildQuery');
			},
			pickGroupBy: function pickGroupBy(v) {
				this.set('selectedGroupBy', v);
				this.send('buildQuery');
			},
			showFilters: function showFilters() {
				if (this.get('selectedCollection')) {
					if (this.get('filters').length === 0) {
						this.get('filters').pushObject({ property: '', matchOperator: 'eq', matchType: 'String', matchValue: '' });
					}
					$('#filter-list').modal('show');
				} else {
					this.set('collectionError', true);
				}
			},
			updateFilters: function updateFilters() {
				console.log('updateFilters');
				console.log(this.get('filters'));
				this.send('buildQuery');
			},
			saveQuery: function saveQuery() {
				console.log('saveQuery');

				var savedQuery = {
					name: this.get('queryName'),
					displayType: this.get('selectedResultType'),
					query: this.get('query')
				};
				console.log(JSON.stringify(savedQuery, null, 2));
				var self = this;
				this.get('project').saveQuery(this.get('model'), savedQuery, function (err) {
					if (err) {
						self.get('flashMessages').danger(JSON.stringify(err));
					} else {
						self.get('model').reload();
						self.get('flashMessages').success("Query saved");
					}
				});
			}

		}
	});
});