define('analytics/components/relative-timeframe', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		realativeTos: ['previous', 'this'],
		timeframes: ['minutes', 'hours', 'days', 'weeks', 'months', 'years'],
		selectedRelativeTo: '',
		selectedTimeframe: '',
		timeValue: 1,
		thisVal: 'this',
		prevVal: 'previous',
		didInsertElement: function didInsertElement() {
			console.log('didInsertElement');
			var v = this.get('value');
			var vals = v.split('_');
			this.set('selectedRelativeTo', vals[0]);
			this.set('selectedTimeframe', vals[2]);
			this.set('timeValue', vals[1]);
			console.log(vals);
		},
		valueSetter: (function () {
			this.set('value', this.get('selectedRelativeTo') + '_' + this.get('timeValue') + '_' + this.get('selectedTimeframe'));
		}).observes('timeValue'),
		actions: {
			test: function test() {
				console.log(this.get('attrs'));
				console.log(this.get('relative-to'));
				alert('test: ' + (this.get('selectedRelativeTo') + '_' + this.get('timeValue') + '_' + this.get('selectedTimeframe')));
			},
			pickRelativeTo: function pickRelativeTo(v) {
				this.set('selectedRelativeTo', v);
				this.set('value', this.get('selectedRelativeTo') + '_' + this.get('timeValue') + '_' + this.get('selectedTimeframe'));
			},
			pickTimeframe: function pickTimeframe(v) {
				this.set('selectedTimeframe', v);
				this.set('value', this.get('selectedRelativeTo') + '_' + this.get('timeValue') + '_' + this.get('selectedTimeframe'));
			},
			updateTimeValue: function updateTimeValue() {
				this.get('onUpdateTimeframe')(this.get('selectedRelativeTo') + '_' + this.get('timeValue') + '_' + this.get('selectedTimeframe'));
			}
		}
	});
});