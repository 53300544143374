define('analytics/components/change-password-form', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		actions: {
			changePassword: function changePassword() {
				this.debug('changePassword');
				var model = this.get('model');

				var _getProperties = this.getProperties('oldPassword', 'newPassword', 'confirmPassword');

				var oldPassword = _getProperties.oldPassword;
				var newPassword = _getProperties.newPassword;
				var confirmPassword = _getProperties.confirmPassword;

				this.debug('fields: ' + model.id + ' ' + oldPassword + ' ' + newPassword + ' ' + confirmPassword);

				var valid = true;
				var errors = {};

				if (!oldPassword || oldPassword.length < 6) {
					errors['oldPassword'] = true;
					valid = false;
				} else {
					errors['oldPassword'] = false;
				}

				if (!newPassword || newPassword.length < 6) {
					errors['newPassword'] = true;
					valid = false;
				} else {
					errors['newPassword'] = false;
				}

				if (!confirmPassword || newPassword !== confirmPassword) {
					errors['confirmPassword'] = true;
					valid = false;
				} else {
					errors['confirmPassword'] = false;
				}

				this.set('errors', errors);

				if (valid) {
					this.attrs.changePassword(model.id, oldPassword, newPassword, confirmPassword);
				}
			}
		}
	});
});