define('analytics/routes/project/queries', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    project: _ember['default'].inject.service(),
    actions: {
      loading: function loading(transition, originRoute) {
        console.log('queries loading');
        return true;
      },
      didTransition: function didTransition() {
        console.log('explore didTransition: ' + this.routeName);
        this.get('project').setActiveTab('queries');
        return true; // Bubble the didTransition event
      },
      willTransition: function willTransition(transition) {
        //      if (this.controller.get('userHasEnteredData')) {
        //        this.controller.displayNavigationConfirm();
        //        transition.abort();
        //      }
        console.log('queries willTransition');
      }
    }

  });
});