define('analytics/models/account', ['exports', 'ember-data', 'moment', 'ember-moment/computeds/duration', 'ember-moment/computeds/humanize'], function (exports, _emberData, _moment, _emberMomentComputedsDuration, _emberMomentComputedsHumanize) {
  exports['default'] = _emberData['default'].Model.extend({ email: _emberData['default'].attr('string'),
    company: _emberData['default'].attr('string'),
    createdAt: _emberData['default'].attr('date'),
    accessKey: _emberData['default'].attr('string'),
    readKey: _emberData['default'].attr('string'),
    writeKey: _emberData['default'].attr('string'),
    loginCount: _emberData['default'].attr('number'),
    createdAtInWords: Ember.computed('createdAt', function () {
      return (0, _moment['default'])(this.get('createdAt')).fromNow();
    }),
    //  projects: DS.hasMany()
    projects: _emberData['default'].hasMany('project')

  });
});