define('analytics/helpers/hypot', ['exports', 'ember-math-helpers/helpers/hypot'], function (exports, _emberMathHelpersHelpersHypot) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberMathHelpersHelpersHypot['default'];
    }
  });
  Object.defineProperty(exports, 'hypot', {
    enumerable: true,
    get: function get() {
      return _emberMathHelpersHelpersHypot.hypot;
    }
  });
});