define('analytics/components/explore/query-result-line-chart', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		project: _ember['default'].inject.service(),
		init: function init() {
			this._super.apply(this, arguments);
			console.log("LINE-CHART: init");
			var uu = this.get('project').generateShortUUID();
			this.set('chartId', 'chart-' + uu);
		},
		didRender: function didRender() {
			console.log('LINE-CHART: didRender');
			this._super.apply(this, arguments);

			var chartId = 'line-chart';
			if (this.get('chartId')) {
				chartId = this.get('chartId');
			}

			this.set('chartId', chartId);
			//		console.log('LINE-CHART: queryJSON');
			var queryJSON = this.get('queryJSON');
			//		console.log(queryJSON);
			var project = this.get('project');
			var lineData = undefined;

			if (!Array.isArray(queryJSON)) {
				return;
			}

			if (Object.keys(queryJSON[0]).includes('count')) {

				// single line chart data
				var lineDatasets = [];
				var dataset = {
					'label': 'Count',
					'data': queryJSON.map(function (item) {
						return { x: new Date(item.timeframe.start), y: item.count };
					})
				};
				var clr = project.hexToRGB(project.colors[0]);
				// console.log("Color");
				// console.log(clr);
				dataset['backgroundColor'] = 'rgba(' + clr.r + ',' + clr.g + ',' + clr.b + ',0.4)';
				dataset['borderColor'] = 'rgba(' + clr.r + ',' + clr.g + ',' + clr.b + ',0.9)';
				lineDatasets.push(dataset);
				lineData = {
					datasets: lineDatasets
				};
			} else {

				// multiple datasets
				var lineDatasets = [];

				var transformLineData = function transformLineData(item) {
					return { x: new Date(item.ts), y: item.c };
				};

				for (var i = 0; i < queryJSON.length; i++) {

					console.log(queryJSON[i]._id);
					var dataSet = {
						'label': queryJSON[i]._id
					};
					console.log(queryJSON[i].tc);

					var data = queryJSON[i].tc.map(transformLineData(item));

					dataSet['data'] = data;

					// colors
					if (i < project.colors.length) {
						var clr = project.hexToRGB(project.colors[i]);
						dataSet['backgroundColor'] = 'rgba(' + clr.r + ',' + clr.g + ',' + clr.b + ',0.4)';
						dataSet['borderColor'] = 'rgba(' + clr.r + ',' + clr.g + ',' + clr.b + ',0.9)';
					}

					lineDatasets.push(dataSet);
				}
				lineData = {
					datasets: lineDatasets
				};
			}

			var options = {
				maintainAspectRatio: true,
				responsiveAnimationDuration: 1000,
				scales: {
					xAxes: [{
						type: 'time',
						time: {
							// min: this.get('timeseries').start,
							// max: this.get('timeseries').end,
							displayFormats: {
								quarter: 'MMM YYYY'
							}
						}
					}]
				}
			};
			console.log('options');
			console.log(options);

			var fill = this.get('fill');

			// tweak dataset display options
			lineData.datasets.forEach(function (ds) {
				ds['borderWidth'] = 1;
				ds['pointBorderColor'] = "rgba(75,192,192,1)";
				ds['pointBackgroundColor'] = "rgba(255,255,255,0.9)";
				ds['pointBorderWidth'] = 1;
				ds['pointHoverRadius'] = 5;
				//			ds['pointHoverBackgroundColor'] = '#1983C3';
				ds['pointHoverBackgroundColor'] = 'rgba(75,192,192,0.5)';
				ds['pointHoverBorderColor'] = "rgba(75,192,192,1)";
				ds['pointHoverBorderWidth'] = 1;
				ds['pointRadius'] = 1;
				ds['pointHitRadius'] = 20;

				ds['fill'] = fill;
			});

			console.log('DATA - line chart');
			console.log(lineData);

			var ctx = document.getElementById(chartId);
			var chartParams = {
				type: 'line',
				data: lineData,
				options: options
			};

			console.log('DATA - chart params');
			console.log(lineData);

			new Chart(ctx, chartParams);
		}

	});
});