define('analytics/components/explore/query-builder-interval-picker', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		intervalTypes: ['minutes', 'hours', 'days', 'weeks', 'months', 'years'],
		actions: {
			pick: function pick(v) {
				this.sendAction('action', v);
			},
			enable: function enable() {
				this.sendAction('enable');
			},
			disable: function disable() {
				this.sendAction('disable');
			}
		}
	});
});