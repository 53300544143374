define('analytics/controllers/login', ['exports', 'ember', 'ember-validations'], function (exports, _ember, _emberValidations) {
	exports['default'] = _ember['default'].Controller.extend(_emberValidations['default'], {
		showErrors: false,
		session: _ember['default'].inject.service(),
		validations: {
			email: {
				presence: true,
				length: { minimum: 6 }
			},
			password: {
				presence: true,
				length: { minimum: 6 }
			}
		},
		actions: {

			login: function login() {
				var _this = this;

				if (this.get('isValid')) {
					var _getProperties = this.getProperties('email', 'password');

					var email = _getProperties.email;
					var password = _getProperties.password;

					this.debug('login ' + email);
					this.get('session').login(email, password).then(function () {
						_this.get('flashMessages').success("Signed in successfully");
						_this.transitionToPreviousRoute();
					})['catch'](function (reason) {
						_this.set("showErrors", true);
						_this.get('flashMessages').danger(reason);
					});
				} else {
					this.set("showErrors", true);
				}
			}

		}, // actions

		transitionToPreviousRoute: function transitionToPreviousRoute() {
			this.debug("Transitioning to previous");
			var previousTransition = this.get('previousTransition');
			this.debug('get previousTransition: ' + previousTransition);
			if (previousTransition) {
				this.set('previousTransition', null);
				previousTransition.retry();
			} else {
				// Default back to homepage
				this.transitionToRoute('index');
			}
		}

	});
});