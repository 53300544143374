define('analytics/routes/account-edit', ['exports', 'ember', 'analytics/mixins/authenticated-route', 'analytics/config/environment'], function (exports, _ember, _analyticsMixinsAuthenticatedRoute, _analyticsConfigEnvironment) {
	exports['default'] = _ember['default'].Route.extend({

		session: _ember['default'].inject.service(),
		account: _ember['default'].inject.service(),

		beforeModel: function beforeModel(transition) {

			// the account must be set before proceeding
			// if the user is reloading the page or following a bookmark
			// the session won't be setup yet
			// therefore this is needed to force the session to get setup in adavance

			var session = this.get('session');
			var currentAccount = session.get('currentAccount');
			var account = session.get('account');

			this.debug('currentAccount: ' + currentAccount);
			if (!currentAccount) {
				var token = Cookies.get(_analyticsConfigEnvironment['default'].constants.token_key);
				session.set('authToken', token);
				if (!!token) {
					return account.find(token);
				} else {
					transition.abort();
				}
			}
		},
		afterModel: function afterModel(account, transition) {
			this.debug('afterModel');
			this.debug('account: ' + account);
			this.debug('account.id: ' + account.id);

			var session = this.get('session');
			var currentAccount = session.get('currentAccount');

			this.debug('currentAccount.id: ' + currentAccount._id);

			if (currentAccount._id == account.id) {
				this.debug('User is accessing their own account');
			} else {
				this.debug('User is trying to access another account');
				// check for an administrative user
				return session.get('account').isAllowed('accounts', 'update', currentAccount._id);
			}
		}

	});
});