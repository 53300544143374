define('analytics/components/project/last-events', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		project: _ember['default'].inject.service(),
		willRender: function willRender() {
			console.log('willRender');
		},
		actions: {
			pickCollection: function pickCollection(v) {
				console.log(v);
				var self = this;
				this.get('project').lastEvents(this.get('model'), v, function (r) {
					console.log(r);
					var a = r.map(function (e) {
						return { ts: e.infinite.timestamp, json: JSON.stringify(e, null, 2) };
					});
					self.set('events', a);
				});
			}
		}
	});
});