define('analytics/components/explore/bar-chart', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		colors: ['#559ac3', '#556ac3', '#7c55c3', '#c155c3', '#ce5a79', '#dc7360', '#dc9b60', '#dcb460', '#dccc60', '#ced75e', '#8bcb58', '#55c386', '#eda1a4', '#eebda2', '#eecea2', '#eedda2', '#d8e89e', '#9ee098', '#98d8df', '#98b8df', '#9f98df', '#c398df', '#e29ac9'],
		didRender: function didRender() {
			console.log('didRender');
			this._super.apply(this, arguments);

			var data = this.get('data');
			data.datasets[0]['backgroundColor'] = this.get('colors');
			console.log(data);

			var ctx = document.getElementById("myChart");

			var options = {
				//	maintainAspectRatio: true,
				legend: {
					display: true
				}

			};

			var chart = new Chart(ctx, {
				type: 'horizontalBar',
				data: data,
				options: options
			});
		}
	});
});