define('analytics/components/explore/filter-list', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		actions: {
			newFilter: function newFilter() {
				var fltr = {
					property: '',
					matchOperator: '=',
					matchType: 'String',
					matchValue: ''
				};
				this.get('filters').pushObject(fltr);
			},
			removeFilter: function removeFilter(i) {
				this.get('filters').removeAt(i);
			},
			done: function done() {
				this.sendAction();
			}
		}
	});
});