define('analytics/components/explore/query-result-count', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		project: _ember['default'].inject.service(),
		count: _ember['default'].computed('data', function () {
			var data = this.get('data');
			var c = this.get('project').nFormatter(data.count);
			return c;
		}),
		compareCount: _ember['default'].computed('compareData', function () {
			var data = this.get('compareData');
			var c = this.get('project').nFormatter(data.count);
			return c;
		}),
		comparePeriod: _ember['default'].computed('compareData', function () {
			var data = this.get('compareData');
			if (data.timeValue === 1) {
				var tp = data.timePeriod.slice(0, -1);
				var s = 'last ' + tp;
				if (data.timePeriod === 'days') {
					s = 'yesterday';
				}
				return s;
			} else {
				return data.timeValue + ' ' + data.timePeriod + ' ago';
			}
		}),
		comparePercentage: _ember['default'].computed('compareData', function () {
			var data = this.get('data');
			var compareData = this.get('compareData');
			if (data.count > compareData.count) {
				var p = (1 - compareData.count / data.count) * 100;
				return p.toFixed(2);
			} else {
				var p = -(1 - data.count / compareData.count) * 100;
				return p.toFixed(2);
			}
		}),
		negative: _ember['default'].computed('compareData', function () {
			var data = this.get('data');
			var compareData = this.get('compareData');
			return data.count < compareData.count;
		}),
		positive: _ember['default'].computed('compareData', function () {
			var data = this.get('data');
			var compareData = this.get('compareData');
			return data.count >= compareData.count;
		}),
		overrideHeight: _ember['default'].computed(function () {
			var h = this.get('height');
			if (h) {
				return 'height: ' + h + 'px';
			}
		})
	});
});