define('analytics/components/project/dashboard-edit-row', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		nameUpdated: _ember['default'].computed('name', function () {
			console.log(name);
		}),
		layouts: ['Full', '2 col', '3 col', '4 col', 'Wide left', "Wide right"],
		actions: {
			pickLayout: function pickLayout(v) {
				console.log('pickLayout: ' + v);
				var row = this.get('row');
				console.log(row);
				// too few columns (added to fix increased # of rows)
				if (row.columns.length < 4) {
					row.columns.pushObject({ queryName: null });
				}
				console.log(row);
				this.set('row.layout', v);
			},
			removeRow: function removeRow(idx) {
				console.log(this.get('row'));
				this.sendAction('removeRow', idx);
			},
			moveUp: function moveUp() {
				this.sendAction('moveUp', this.get('index'));
			},
			moveDown: function moveDown() {
				this.sendAction('moveDown', this.get('index'));
			}
		}
	});
});