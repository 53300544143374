define('analytics/components/project/dashboard-editor', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		actions: {
			newRow: function newRow() {
				var rows = this.get('dashboard.rows');
				var newRow = {
					name: '',
					layout: '2 col',
					height: 270,
					columns: [{ queryName: null, isCompare: false, compareTimeValue: 1, comparePeriod: 'months' }, { queryName: null, isCompare: false, compareTimeValue: 1, comparePeriod: 'months' }, { queryName: null, isCompare: false, compareTimeValue: 1, comparePeriod: 'months' }, { queryName: null, isCompare: false, compareTimeValue: 1, comparePeriod: 'months' }]
				};
				rows.pushObject(newRow);
			},
			removeRow: function removeRow(idx) {
				var rows = this.get('dashboard.rows');
				rows.removeAt(idx, 1);
			},
			moveUp: function moveUp(idx) {
				var rows = this.get('dashboard.rows');
				var sourceRow = rows[idx];
				rows[idx] = rows[idx - 1];
				rows[idx - 1] = sourceRow;
				rows.arrayContentDidChange(idx - 1, 0, 0);
			},
			moveDown: function moveDown(idx) {
				var rows = this.get('dashboard.rows');
				var sourceRow = rows[idx];
				rows[idx] = rows[idx + 1];
				rows[idx + 1] = sourceRow;
				rows.arrayContentDidChange(idx, 0, 0);
			},
			save: function save() {
				// console.log('save');
				// console.log('DASHBOARD');
				// console.log(this.get('dashboard'));
				// console.log(JSON.stringify(this.get('dashboard'),null,2));
				// console.log('DASHBOARD.ROWS');
				// console.log(this.get('dashboard.rows'));
				this.sendAction('finished');
			},
			updateRow: function updateRow(idx, row) {
				console.log('updateRow: ' + idx);
				console.log(row);
			}
		}
	});
});