define('analytics/routes/project/explore', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    // model(params){
    // 	return this.store.findRecord('project', params.project_id);
    // 	},
    project: _ember['default'].inject.service(),
    actions: {
      loading: function loading(transition, originRoute) {
        console.log('explore loading');
        return true;
      },
      didTransition: function didTransition() {
        console.log('explore didTransition: ' + this.routeName);
        this.get('project').setActiveTab('explore');

        // editing a saved query?
        var queryIndex = this.controller.get('queryIndex');
        if (queryIndex && queryIndex !== -1) {
          this.controller.send('loadSavedQuery');
        }

        return true; // Bubble the didTransition event
      },
      didRender: function didRender() {
        console.log('explore didRender');
      },
      willTransition: function willTransition(transition) {
        //      if (this.controller.get('userHasEnteredData')) {
        //        this.controller.displayNavigationConfirm();
        //        transition.abort();
        //      }
        console.log('explore willTransition');
        this.controller.set('queryIndex', null);
      }
    }
  });
});