define('analytics/controllers/project/dashboard', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Controller.extend({
		isEditing: false,
		project: _ember['default'].inject.service(),
		routing: _ember['default'].inject.service('-routing'),
		currentRoute: _ember['default'].computed(function () {
			return this.get('routing.currentRouteName');
		}),
		sparkline: function sparkline(collectionName) {
			this.debug(collectionName);
			this.get('project').sparkLine(collectionName);
		},
		actions: {
			stopEditing: function stopEditing() {
				alert('here');
				this.set('isEditing', false);
			},
			editDashboard: function editDashboard() {
				this.set('isEditing', true);
				this.transitionToRoute('project.dashboard.edit');
			}

		}

	});
});