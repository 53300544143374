define('analytics/routes/project/settings/detail', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    project: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),
    actions: {
      didTransition: function didTransition() {
        this.get('project').setActiveSettingsTab('detail');
        return false; // Bubble the didTransition event
      },
      deleteProject: function deleteProject(the_id) {
        var _this = this;

        if (confirm('Delete project and all of it\'s data!? ' + the_id)) {

          this.store.findRecord('project', the_id, { reload: true }).then(function (rec) {
            return rec.destroyRecord();
          }).then(function () {
            _this.transitionTo('/account/' + _this.get('session.currentAccount._id'));
          });
        }
      }
    }
  });
});