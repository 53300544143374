define('analytics/components/explore/filter-line-item', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		matchOperators: [{ name: '= Equal to', value: 'eq' }, { name: '≠ Not equal to', value: 'ne' }, { name: '> Greater than', value: 'gt' }, { name: '≥ Greater than or equal to', value: 'gte' }, { name: '< Less than', value: 'lt' }, { name: '≤ Less than or equal to', value: 'lte' }, { name: '∃ Property exists', value: 'exists' }, { name: '≈ String contains', value: 'regex' }, { name: '∋ Matches any value in list', value: 'in' }, { name: '∌ Matches any value NOT in list', value: 'nin' }],
		matchTypes: ['String', 'Number', 'Null', 'List', 'Boolean', 'DateTime'],
		matchValue: '',
		// observeMatchValue: function(){
		// 	this.get('filter').matchValue = this.get('matchValue');
		// }.observes('matchValue'),
		actions: {
			removeFilter: function removeFilter() {
				this.sendAction('action', this.get('index'));
			},
			updateProperty: function updateProperty(v) {
				this.set('filter.property', v);
				console.log('updateProperty: ' + v);

				var field = this.get('fields').find(function (f) {
					return f.name === v;
				});

				switch (field.type) {
					case 'string':
						this.set('filter.matchType', 'String');
						break;
					case 'datetime':
						this.set('filter.matchType', 'DateTime');
						break;
					case 'array':
						this.set('filter.matchType', 'List');
						break;
					case 'number':
						this.set('filter.matchType', 'Number');
						break;
					case 'boolean':
						this.set('filter.matchType', 'Boolean');
						break;
				}

				console.log(field.type);
			},
			updateMatchType: function updateMatchType(v) {
				this.set('filter.matchType', v);
			},
			updateMatchOperator: function updateMatchOperator(v) {
				this.set('filter.matchOperator', v);
			},
			updateBoolean: function updateBoolean(v) {
				if (v === 'true') {
					this.set('filter.matchValue', true);
				} else {
					this.set('filter.matchValue', false);
				}
			}
		}
	});
});