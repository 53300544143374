define('analytics/controllers/project-new', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Controller.extend({
		session: _ember['default'].inject.service(),
		project: _ember['default'].inject.service(),
		newModel: {},
		actions: {
			save: function save() {
				var _this = this;

				var model = this.store.createRecord('project', this.get('newModel'));
				model.set('accountId', this.get('session.currentAccount')._id);
				model.save().then(function () {
					console.log("SUCCCESSS");
					_this.transitionToRoute('project.dashboard', model);
				})['catch'](function (error) {
					console.log(error);

					var msg = '';
					error.errors.forEach(function (item, idx) {
						msg = msg + item.detail + "\n";
					});
					console.log(msg);

					_this.get('flashMessages').danger(msg);
				});
			}
		}
	});
});