define('analytics/components/explore/line-graph', ['exports', 'ember', 'analytics/config/environment'], function (exports, _ember, _analyticsConfigEnvironment) {
	exports['default'] = _ember['default'].Component.extend({
		didRender: function didRender() {
			console.log('didRender');
			this._super.apply(this, arguments);

			var options = {
				scales: {
					xAxes: [{
						type: 'time',
						time: {
							// min: this.get('timeseries').start,
							// max: this.get('timeseries').end,
							displayFormats: {
								quarter: 'MMM YYYY'
							}
						}
					}]
				}
			};

			console.log('options');
			console.log(options);

			var data = this.get('data');
			var fill = this.get('isArea');
			console.log('FILL:' + fill);

			// tweak dataset display options
			data.datasets.forEach(function (ds) {
				ds['borderWidth'] = 1;
				ds['pointBackgroundColor'] = 'rgba(255,255,255,0.9)';
				ds['pointBorderWidth'] = 1;
				ds['pointHoverRadius'] = 5;
				ds['pointHoverBackgroundColor'] = '#1983C3';
				ds['fill'] = fill;
			});

			console.log('DATA - line chart');
			console.log(data);

			var ctx = document.getElementById("myChart");

			var chart = new Chart(ctx, {
				type: 'line',
				data: data,
				options: options
			});
		}

		// 	chartsInitialized: false,
		// 	didRender: function(){
		// 		console.log('didRender');
		// 		this._super(...arguments);

		// 		let graphData = this.get('data');
		// 		console.log('container: ' + this.get('domContainer'));
		// 		let contain = document.getElementById(this.get('domContainer'));

		// 		function drawIntervalChart(){
		// 			let data = google.visualization.arrayToDataTable(graphData);

		// 			console.log('the-line-graph-data');
		// 			console.log(graphData);

		// 			let width = contain.offsetWidth;
		// 			let height = contain.offsetHeight;

		// 			let options = {
		// 				height: height,
		// 				width: width,
		// //				colors: [ENV.APP.BLUE_PRIMARY],
		// 				interpolateNulls: true,
		// 				animation: {
		// 					duration: 250,
		// 					startup: true
		// 				},
		// //				curveType: 'function',
		// 				legend: {
		// 					position: 'none'
		// 				}
		// 			};

		// 			let chart = new google.visualization.LineChart(document.getElementById("line-graph"));
		// 			chart.draw(data, options);

		// 		}
		// 		if (this.get('chartsInitialized')) {
		// 			drawIntervalChart();
		// 		} else {
		// 			google.charts.load('current', {'packages':['corechart']});
		//   		google.charts.setOnLoadCallback(drawIntervalChart);
		//   		this.set('chartsInitialized', true);
		// 		}

		// 	},
		// 	actions: {

		// 	}
	});
});