define('analytics/routes/project', ['exports', 'ember', 'analytics/config/environment'], function (exports, _ember, _analyticsConfigEnvironment) {
  exports['default'] = _ember['default'].Route.extend({
    project: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),
    beforeModel: function beforeModel(transition) {

      // the account must be set before proceeding
      // if the user is reloading the page or following a bookmark
      // the session won't be setup yet
      // therefore this is needed to force the session to get setup in adavance

      var session = this.get('session');
      var currentAccount = session.get('currentAccount');
      var account = session.get('account');

      if (!currentAccount) {
        var token = Cookies.get(_analyticsConfigEnvironment['default'].APP.TOKEN_KEY);
        session.set('authToken', token);
        if (!!token) {
          return account.find(token);
        } else {
          this.transitionTo('login');
          transition.abort();
        }
      }
    },
    afterModel: function afterModel(model, transition) {
      console.log('Project afterModel');
      this.get('project').setCurrentFromModel(model);
    },
    actions: {
      didTransition: function didTransition() {
        //    	console.log('project didTransition');
        //    	console.log('routeName:' + this.routeName);
        return true; // Bubble the didTransition event
      },
      save: function save() {
        alert(this.get('model'));
        alert('saving');
      }
    }
  });
});